import React from 'react';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import 'firebase/firestore';

const ErrorGeolocationTracker = ({ errorTracker }) => {

  return (
    <>
      {errorTracker && (
        <div style={{ justifySelf: 'left', marginLeft: '15px', textAlign: 'center', color: 'red' }}>
          <button 
          style={{ backgroundColor: 'transparent', color: 'red', border: 'none', padding: '5px 0px', borderRadius: '5px', cursor: 'pointer', fontSize: '10px', width: '160px', textAlign: 'left' }}
          onClick={() => alert(errorTracker)}
          >
            <LocationOffIcon style={{ fontSize: 12 }}/>
            <span>  Ubicación deshabilitada</span>
          </button>
        </div>
      )}
    </>
  );
};
  
export default ErrorGeolocationTracker;
