import './App.css';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from './components/Header';
import ClothingInfo from './components/ClothingInfo';
import GeolocationTracker from './components/GeolocationTracker';
import ScanButton from './components/ScanButton';
import constants from './constants';
import TrackDeviceUUID from './components/TrackDeviceUUID';

const isBarcodeScannerEnabled = (tenant) => {
  return tenant === constants.FORTER_TENANT_NAME;
}

const getWebServicesURL = (tenant) => {
  if (tenant == null) return constants.LM_WEB_SERVICE;
  if (tenant === constants.FORTER_TENANT_NAME) return constants.FORTER_WEB_SERVICE;
  return constants.LM_WEB_SERVICE;
}

export const loadTenantConfiguration = (tenant, setIsDarkMode) => {
  if (tenant == null) {
    setIsDarkMode(true);
    document.title = "LosMuchachos FastStock";
    import('./styles/lmuchachos.css');
    
  } else if (tenant === constants.FORTER_TENANT_NAME) {
    setIsDarkMode(false);
    import('./styles/whitemode.css');
    import('./styles/forter.css');
    document.title = "Forter FastStock";
    
  }
}

const filterBySubstore = (substore, data) => {
  
  let filterValue = "RUS";
  if (substore === constants.FORTER_SUBSTORE_DIXIE_NAME) filterValue = "OUT";
  data.stock.forEach(item => item.stores = item.stores.filter(store => store.subcompany === filterValue && store.storeName !== "FERIA"));
  return data;
}

const Test = () => {
  useEffect(() => {
    /*
    Query logic
    */
    console.log('i fire once');
  }, []);
};



const App = () => {
  const { tenant, substore, id_item } = useParams();
  const [item, setItem] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTenant, setLoadingTenant] = useState(true);

  const [errorTracker, setErrorTracker] = useState(null);

  useEffect(() => {
    loadTenantConfiguration(tenant, setIsDarkMode);
    setLoadingTenant(false);
  }, [tenant]);

  useEffect(() => {
    setLoading(true);
    setError(null);
    fetch(`${getWebServicesURL(tenant)}${id_item}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*' // Optionally add CORS header
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (substore){
          let filteredData = filterBySubstore(substore, data);
          setItem(filteredData);
        } else {
          setItem(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setError("No se pudo conectar al servidor de datos. Es posible que el servicio esté temporalmente fuera de línea debido a mantenimiento. Por favor, intenta de nuevo más tarde.");
        setLoading(false);
      });
  }, [id_item, tenant, substore]);

  return (
    <div className={`background background-`+tenant}>
      {!loadingTenant && 
        <>
          <TrackDeviceUUID />
          <Header tenant={tenant} substore={substore} isDarkMode={isDarkMode} />
          <div className='mainContainer'>
            {isDarkMode ? <div className='encontraTuTalle' /> :
                          <h3 style={{ fontStyle: 'italic', marginBottom: '40px' }}>ENCONTRÁ TU TALLE</h3>
                          
            }
            {/* <ReactImageTint style={{
                            height: '3vh',
                            width: '40%',
                            margin: '20px 25% 10px 25%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center'
                          }} src={encontraTuTalle} color="#101010" />  */}

            {loading || loadingTenant ? (
              <div className="loaderContainer">
                <div className={"loader loader-"+tenant}></div>
              </div>
            ) : error ? (
              <div className="errorContainer">
                <b>❌ Error de conexión con el servidor</b>
                <br />
                <br />
                {error}
              </div>
            ) : item ? (
              <>
                <ClothingInfo item={item} errorTracker={errorTracker} />
                <GeolocationTracker item={item} errorTracker={errorTracker} setErrorTracker={setErrorTracker} />
              </>
            ) : (
              <div className="noStockContainer">No hay información para ese árticulo</div>
            )}
            {isBarcodeScannerEnabled(tenant) && ( 
              <div className='scanButtonContainer'>
                <ScanButton tenant={tenant} substore={substore}/>
              </div>
            )}
          </div>
          <Test />
        </> 
      }
      
    </div>
  );
};

export default App; 
