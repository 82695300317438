export const constants = {
    LM_WEB_SERVICE: "https://lm-dapie.com:8088/stocks/",
    FORTER_WEB_SERVICE: "https://faststock-services.org/POSCommWebAPI/getarticulo?pCodigoBarras=",
    FORTER_TENANT_NAME: "forter",
    FORTER_SUBSTORE_DIXIE_NAME: "dixie",
    CITIES_FORTER:  [
      { name: "Dixie Cabal", lat: -34.880879, lon: -56.155149 },
      { name: "Dixie Roxlo", lat: -34.90403860951803, lon: -56.181881775511826 },
      { name: "Dixie Ejido", lat: -34.905503, lon: -56.187069 },
      { name: "Dixie 8 Octubre", lat: -34.875074, lon: -56.140884 },
      { name: "Dixie Geant", lat: -34.86559235640191, lon: -56.02407949815081 },
      { name: "Dixie Paso Molino", lat: -34.8581288188039, lon: -56.21832319999999 },
      { name: "Dixie Durazno", lat: -33.37918213637132, lon: -56.524347057671186 },
      { name: "Outlet Tunel", lat: -34.89225430042598, lon: -56.16226772883559 },
      { name: "Dixie Florida", lat: -34.106463900685334, lon: -56.205450199999994 },
      { name: "Dixie Belloni", lat: -34.82330602994222, lon: -56.1408206 },
      { name: "Dixie Colon", lat: -34.80382814784151, lon: -56.22300370000001 },
      { name: "Dixie Uam", lat: -34.82021766393327, lon: -56.268248290897645 },
      { name: "Dixie Cerro", lat: -34.87342861061509, lon: -56.247603228835594 },
      { name: "Tres Cruces", lat: -34.89321939338136, lon: -56.16616810336874 },
      { name: "Nuevo Centro", lat: -34.86898521299362, lon: -56.1698062288356 },
      { name: "Portones", lat: -34.881090306513194, lon: -56.0814954711644 },
      { name: "Montevideo Shopping", lat: -34.902759096941956, lon: -56.13680720000001 },
      { name: "Salto", lat: -31.387601892789064, lon: -57.9656592580115 },
      { name: "Costa Urbana", lat: -34.841026122361264, lon: -55.99339189999999 },
      { name: "F. Atlantico", lat: -34.91578394121706, lon: -54.9607745708899 }
    ],
    CITIES_LM:  [
      { name: "Colonia Shopping", lat: -34.46619923126388, lon: -57.83450674693861 }, 
      { name: "Colonia Centro", lat:  -34.470636449775, lon: -57.848068842357584 },
      { name: "Juan Lacaze", lat: -34.434424845926586, lon: -57.439718946122866 },
      { name: "Tarariras", lat: -34.26395125230536,  lon: -57.611990575372225 },
      { name: "Rosario", lat: -34.313868499129036, lon: -57.35419249374869 },
      { name: "Nueva Helvecia", lat: -34.28410225460711, lon: -57.23484181811289 },
      { name: "Cardona", lat: -33.87239053824258, lon: -57.367445477655906 },
    ],
    CITIES_LATOFFSET: 0.0009, // Aproximadamente 100 metros para la latitud
    CITIES_LONOFFSET: 0.0012, // Aproximadamente 100 metros para la longitud (varía según la latitud)
  };

// const protocol = (window.location.protocol === 'https:') ? 'https:' : 'http:';
// const protocol = "https:";

export default constants;