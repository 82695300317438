import React from "react";
import packageInfo from "../../package.json"; 

const VersionTracker = () => {
  const inlineStyles = {
    fontSize: "0.5rem",
    color: "#666",
    textAlign: "center",
    marginTop: "20px",
    position: "fixed",
    right: "5px",
    bottom: "5px"
  };

  return (
    <div style={inlineStyles}>
      <small>Version: {packageInfo.version}</small>
    </div>
  );
};

export default VersionTracker;