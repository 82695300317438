import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const TrackDeviceUUID = () => {
  useEffect(() => {
    // Comprobar si ya existe un UUID en localStorage
    let deviceUUID = localStorage.getItem('deviceUUID');

    // Si no existe, crear uno nuevo y guardarlo en localStorage
    if (!deviceUUID) {
      deviceUUID = uuidv4();
      localStorage.setItem('deviceUUID', deviceUUID);
    }
  }, []); // Se ejecuta solo una vez cuando el componente se monta

  return null;  // No es necesario renderizar nada visualmente
};

export default TrackDeviceUUID;