// Desc: Alert the user if they are using an in-app browser
const InAppBrowserAlert = () => {

    function isInAppBrowser() {
        const userAgent = navigator.userAgent || window.opera;
        return /FBAN|FBAV|Instagram|Twitter|Line|WhatsApp/i.test(userAgent);
    }

    function isSamsungInternetLite() {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes("samsungbrowser") && userAgent.includes("lite");
    } 

    if (isInAppBrowser()) {
        alert("Para iniciar sesión, abre esta página en tu navegador.");
        window.open(window.location.href, "_blank");
    }

    if (isSamsungInternetLite()) {
        alert(
          "Detectamos que estás usando Samsung Internet Lite. Para una mejor experiencia, configura Google Chrome como tu navegador predeterminado. Sigue estos pasos:\n\n" +
          "1. Abre la Configuración de tu dispositivo.\n" +
          "2. Ve a Aplicaciones o Administrador de Aplicaciones.\n" +
          "3. Toca en 'Aplicaciones predeterminadas' y selecciona Navegador.\n" +
          "4. Elige Google Chrome como tu navegador predeterminado."
        );
    }

 
  
    return null;
};
  
export default InAppBrowserAlert;