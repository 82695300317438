import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../components/Header';
import { loadTenantConfiguration } from '../App';
import FirebaseAuth from '../components/FirebaseAuth';
import { firebaseAuthUIConfig, firebaseAuth } from '../components/Firebase';
import './LoginView.css';
import InAppBrowserAlert from '../components/InAppBrowserAlert';

const LoginView = () => {
  const { tenant } = useParams();
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [loadingTenant, setLoadingTenant] = useState(true);

  useEffect(() => {
    loadTenantConfiguration(tenant, setIsDarkMode);
    setLoadingTenant(false);
  }, [tenant]);

  // Define your callback function
  const uiCallback = (ui) => {
    // Your callback logic here
    
  };

  return (
    <div className={`background background-`+tenant}>
      {!loadingTenant && 
        <>
          <Header tenant={tenant} isDarkMode={isDarkMode} />
          <InAppBrowserAlert />
          <div className='mainContainer'>
            <FirebaseAuth uiConfig={firebaseAuthUIConfig} firebaseAuth={firebaseAuth} className="authContainer" uiCallback={uiCallback} />
            <div className="loginInfoContainer">
                <p className="loginInfo">
                  El login es solo útil para empleados.<br />  Para trackear cantidad de escaneos.
                </p>
            </div>
            
          </div>
        </>
      }
    </div>
  );
};

export default LoginView; 
