
import './ClothingInfo.css';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import locationIcon from '../images/location_icon.png';
import constants from '../constants';
import ErrorGeolocationTracker from './ErrorGeolocationTracker';

const capitalizeFirstLetterOfEveryWord = (string) => {
  if (!string) return '';
  return string.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}

const fixAddress = (storeName, storeAddress) => {
    let address = storeAddress;
    if (storeName === 'DIXIE ROXLO'){
      address = 'Av. 18 de Julio 1585 Montevideo';
    } else if (storeName === 'TRES CRUCES'){
      address = 'ARTIGAS BLVR. GRAL. 1825 LOCAL 33 (Shopping Tres Cruces)';
    } else if (storeName === 'PORTONES'){
      address = 'Portones Shopping';
    } else if (storeName === 'SALTO'){
      address = 'Uruguay 778 Salto';
    } else if (storeName === 'DIXIE BELLONI'){
      address = 'Jose Belloni 4387';
    } else if (storeName === 'COSTA URBANA'){
      address = 'Costa Urbana Shopping';
    } else if (storeName === 'F. ATLANTICO'){
      address = 'Atlantico Shopping';
    }
    return address;
}

const disableShowPrice = (tenant, substore) => {
  return tenant === constants.FORTER_TENANT_NAME && substore === constants.FORTER_SUBSTORE_DIXIE_NAME;
}
 
const ClothingInfo = ({ item, errorTracker }) => {
    const { tenant, substore } = useParams();
    const [selectedColor, setSelectedColor] = useState('');
    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const filteredVariants = selectedColor
        ? item.stock.filter((variant) => variant.color === selectedColor)
        : item.stock;

    // Esta función procesa los datos de una variante para generar una matriz de objetos que se puede usar para llenar la tabla.
    const processVariantData = (variant) => {
      const sizes = {};
      const totals = {};
    
      // First, create a list of all unique sizes available across all stores
      const allSizes = [...new Set(variant.stores.flatMap(store => store.sizes.map(size => size.size)))];
    
      // Then, use this list to construct the sizes and totals objects
      allSizes.forEach((size) => {
        sizes[size] = {};
        totals[size] = 0;
      });
    
      variant.stores.forEach((store) => {
        store.sizes.forEach((size) => {
          sizes[size.size][store.storeName] = size.quantity;
          totals[size.size] += size.quantity;
        });
      });
    
      return { sizes, totals };
    };

    const itemHasStock = item.stock.length > 0;

    return (
        <div className="notranslate">
            <h2>{item.title}</h2>
            <div className="infoContainer">
              <h5 className="notranslate">{capitalizeFirstLetterOfEveryWord(item.family)} - {capitalizeFirstLetterOfEveryWord(item.subfamily)} - {item.refproveedor}</h5>
              {!disableShowPrice(tenant, substore) && <h4>$ {item.net_price}</h4>}
            </div>
            {errorTracker && <ErrorGeolocationTracker errorTracker={errorTracker} />}
            {itemHasStock ? (
              <>
                <div className="filterColorContainer">
                  <select value={selectedColor} onChange={(e) => handleColorClick(e.target.value)} className="dropdown">
                    <option value="">Color: TODOS</option>
                    {item.stock.map((variant) => (
                      <option
                        key={variant.color}
                        value={variant.color}
                      >
                        {variant.color}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="stockContainer">
                  {filteredVariants.map((variant) => {
                    const { sizes: variantData, totals } = processVariantData(variant);
                    const sizeNames = Object.keys(variantData);
                    const allStores = [...new Set(sizeNames.flatMap((sizeName) => Object.keys(variantData[sizeName])))];

                    return (
                      <div key={variant.color} className="notranslate">
                        <h4 className="colorLabel">Color: {variant.color}</h4>
                        <table>
                          <thead>
                            <tr>
                              <th>Tienda/Talle</th>
                              {sizeNames.map((sizeName) => (
                                <th className="notranslate" key={sizeName}>{sizeName}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                          {allStores.map((store) => {
                              // Find the store address based on store name
                              const storeData = variant.stores.find(s => s.storeName === store);
                              const storeAddress = storeData ? storeData.storeadress : '';
                              return (
                                <tr key={store}>
                                  <td className="notranslate">
                                    {storeAddress ? (
                                        <a 
                                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fixAddress(storeData.storeName, storeAddress))}`} 
                                          target="_blank" 
                                          rel="noopener noreferrer"
                                          className="store-link"
                                        >
                                          {capitalizeFirstLetterOfEveryWord(store)}  <img src={locationIcon} alt='📍' className="map-icon" />
                                          
                                        </a>
                                      ) : (
                                        capitalizeFirstLetterOfEveryWord(store)
                                      )}
                                  </td>
                                  {sizeNames.map((sizeName) => (
                                    <td key={sizeName}>{variantData[sizeName][store] || 0}</td>
                                  ))}
                                </tr>
                              );
                            })}
                            <tr className="total-row">
                              <td>Total</td>
                              {sizeNames.map((sizeName) => (
                                <td key={sizeName}>{totals[sizeName] || 0}</td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="noStockContainer"><h4>No hay stock para el artículo: {item.id}</h4></div>
              )
            }
            
        </div>
    );
};

export default ClothingInfo;