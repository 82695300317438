import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTenantCollection } from './Firebase.js';
import { getDocs, query, where } from 'firebase/firestore';
import 'firebase/firestore';
import './ChartUsage.css';
import constants from '../constants.js';
import EmailDataDisplay from './EmailDataDisplay.js';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'StockScanner - Gráfica de uso por mes',
    },
  },
};

const optionsByDay = {
  ...options,
  plugins: {
    ...options.plugins,
    title: {
      display: true,
      text: 'StockScanner - Gráfica de uso por día',
    },
    
  }
};

// Dixie Cabal -34.880879, -56.155149
// Dixie Roxlo -34.903966, -56.181903
// Dixie Ejido -34.905503, -56.187069
// Dixie 8 Octubre -34.875074, -56.140884
// Dixie Geant -34.86559235640191, -56.02407949815081
// Dixie Paso Molino -34.8581288188039, -56.21832319999999
// Dixie Durazno -33.37918213637132, -56.524347057671186
// Outlet Tunel -34.89225430042598, -56.16226772883559
// Dixie Florida -34.106463900685334, -56.205450199999994
// Dixie Belloni -34.82330602994222, -56.1408206
// Dixie Colon -34.80382814784151, -56.22300370000001
// Dixie Uam -34.81818742409715, -56.266720771164394
// Dixie Cerro -34.87342861061509, -56.247603228835594

// Tres Cruces -34.89386909966999, -56.1663526
// Nuevo Centro -34.86898521299362, -56.1698062288356
// Portones -34.881090306513194, -56.0814954711644
// Montevideo Shopping -34.902759096941956, -56.13680720000001
// Salto -31.387601892789064, -57.9656592580115
// Costa Urbana -34.841026122361264, -55.99339189999999
// F. Atlantico -34.91578394121706, -54.9607745708899

//Function that get latitude and longitude parameters and returns the city name
//Colonia Shopping zone is a square -34.468123, -57.836754 to -34.463727, -57.830572
//Colonia Centro zone is a square  -34.472629, -57.849657 to -34.468075, -57.842297
//Juan Lacaze zone is a square -34.448039, -57.467602 to -34.404704, -57.424446
//Tarariras zone is a square -34.277733, -57.628572 to -34.254666, -57.597145
//Rosario zone is a square -34.332687, -57.373598 to -34.302158, -57.337761
//Nueva Helvecia is a square -34.307592, -57.253822 to -34.270458, -57.208394
//Cardona is a square -33.887412, -57.396082 to -33.854749, -57.347968
//Otro if not in the above zones
// const getCityName = (lat, lon) => {

//   // Offset para 100 metros en grados
//   const latOffset = constants.CITIES_LATOFFSET;  // Aproximadamente 100 metros para la latitud
//   const lonOffset = constants.CITIES_LONOFFSET;  // Aproximadamente 100 metros para la longitud (varía según la latitud)

//   // Función para verificar si un punto está dentro de un cuadrado
//   const isWithinSquare = (lat, lon, centerLat, centerLon, latOffset, lonOffset) => {
//     return (
//       lat >= centerLat - latOffset && lat <= centerLat + latOffset &&
//       lon >= centerLon - lonOffset && lon <= centerLon + lonOffset
//     );
//   };

//   // Verificar las zonas existentes
//   if (lat >= -34.468123 && lat <= -34.463727 && lon >= -57.836754 && lon <= -57.830572) {
//     return "Colonia Shopping";
//   } else if (lat >= -34.472629 && lat <= -34.468075 && lon >= -57.849657 && lon <= -57.842297) {
//     return "Colonia Centro";
//   } else if (lat >= -34.448039 && lat <= -34.404704 && lon >= -57.467602 && lon <= -57.424446) {
//     return "Juan Lacaze";
//   } else if (lat >= -34.277733 && lat <= -34.254666 && lon >= -57.628572 && lon <= -57.597145) {
//     return "Tarariras";
//   } else if (lat >= -34.332687 && lat <= -34.302158 && lon >= -57.373598 && lon <= -57.337761) {
//     return "Rosario";
//   } else if (lat >= -34.307592 && lat <= -34.270458 && lon >= -57.253822 && lon <= -57.208394) {
//     return "Nueva Helvecia";
//   } else if (lat >= -33.887412 && lat <= -33.854749 && lon >= -57.396082 && lon <= -57.347968) {
//     return "Cardona"; 

//   // Verificar las nuevas ciudades usando un área de 100 metros
//   } else if (isWithinSquare(lat, lon, -34.880879, -56.155149, latOffset, lonOffset)) {
//     return "Dixie Cabal";
//   } else if (isWithinSquare(lat, lon, -34.90403860951803, -56.181881775511826, latOffset, lonOffset)) {
//     return "Dixie Roxlo";
//   } else if (isWithinSquare(lat, lon, -34.905503, -56.187069, latOffset, lonOffset)) {
//     return "Dixie Ejido";
//   } else if (isWithinSquare(lat, lon, -34.875074, -56.140884, latOffset, lonOffset)) {
//     return "Dixie 8 Octubre";
//   } else if (isWithinSquare(lat, lon, -34.86559235640191, -56.02407949815081, latOffset, lonOffset)) {
//     return "Dixie Geant";
//   } else if (isWithinSquare(lat, lon, -34.8581288188039, -56.21832319999999, latOffset, lonOffset)) {
//     return "Dixie Paso Molino";
//   } else if (isWithinSquare(lat, lon, -33.37918213637132, -56.524347057671186, latOffset, lonOffset)) {
//     return "Dixie Durazno";
//   } else if (isWithinSquare(lat, lon, -34.89225430042598, -56.16226772883559, latOffset, lonOffset)) {
//     return "Outlet Tunel";
//   } else if (isWithinSquare(lat, lon, -34.106463900685334, -56.205450199999994, latOffset, lonOffset)) {
//     return "Dixie Florida";
//   } else if (isWithinSquare(lat, lon, -34.82330602994222, -56.1408206, latOffset, lonOffset)) {
//     return "Dixie Belloni";
//   } else if (isWithinSquare(lat, lon, -34.80382814784151, -56.22300370000001, latOffset, lonOffset)) {
//     return "Dixie Colon";
//   } else if (isWithinSquare(lat, lon, -34.82021766393327, -56.268248290897645, latOffset, lonOffset)) {
//     return "Dixie Uam";
//   } else if (isWithinSquare(lat, lon, -34.87342861061509, -56.247603228835594, latOffset, lonOffset)) {
//     return "Dixie Cerro";

//   // Verificar otras ciudades adicionales
//   } else if (isWithinSquare(lat, lon, -34.89321939338136, -56.16616810336874, latOffset, lonOffset)) {
//     return "Tres Cruces";
//   } else if (isWithinSquare(lat, lon, -34.86898521299362, -56.1698062288356, latOffset, lonOffset)) {
//     return "Nuevo Centro";
//   } else if (isWithinSquare(lat, lon, -34.881090306513194, -56.0814954711644, latOffset, lonOffset)) {
//     return "Portones";
//   } else if (isWithinSquare(lat, lon, -34.902759096941956, -56.13680720000001, latOffset, lonOffset)) {
//     return "Montevideo Shopping";
//   } else if (isWithinSquare(lat, lon, -31.387601892789064, -57.9656592580115, latOffset, lonOffset)) {
//     return "Salto";
//   } else if (isWithinSquare(lat, lon, -34.841026122361264, -55.99339189999999, latOffset, lonOffset)) {
//     return "Costa Urbana";
//   } else if (isWithinSquare(lat, lon, -34.91578394121706, -54.9607745708899, latOffset, lonOffset)) {
//     return "F. Atlantico";

//   // Si no está en ninguna zona
//   } else {
//     return "Otros";
//   }
// };

const getCityName = (lat, lon) => {
  // Offset para 100 metros en grados
  const latOffset = constants.CITIES_LATOFFSET;  // Aproximadamente 100 metros para la latitud
  const lonOffset = constants.CITIES_LONOFFSET;  // Aproximadamente 100 metros para la longitud (varía según la latitud)

  // Función para verificar si un punto está dentro de un cuadrado
  const isWithinSquare = (lat, lon, centerLat, centerLon, latOffset, lonOffset) => {
    return (
      lat >= centerLat - latOffset && lat <= centerLat + latOffset &&
      lon >= centerLon - lonOffset && lon <= centerLon + lonOffset
    );
  };

  // Verificar las ciudades en CITIES_FORTER
  for (const city of constants.CITIES_FORTER) {
    if (isWithinSquare(lat, lon, city.lat, city.lon, latOffset, lonOffset)) {
      return city.name;
    }
  }

  // Verificar las ciudades en CITIES_LM
  for (const city of constants.CITIES_LM) {
    if (isWithinSquare(lat, lon, city.lat, city.lon, latOffset, lonOffset)) {
      return city.name;
    }
  }

  // Si no está en ninguna zona
  return "Otros";
};

//Format string date from YYYY-MM-DD to DD/MM/YYYY
function formatDateToDDMMYYYY(dateString) {
  // Create a Date object from the input date string
  const dateParts = dateString.split('-');
  if (dateParts.length !== 3) {
    // Invalid date format
    return '';
  }

  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Month is zero-based
  const day = parseInt(dateParts[2]);

  const dateObj = new Date(year, month, day);

  // Format the date as DD/MM/YYYY
  const dayFormatted = String(dateObj.getDate()).padStart(2, '0');
  const monthFormatted = String(dateObj.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since it's zero-based
  const yearFormatted = dateObj.getFullYear();

  return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
}

const losMuchachosCities = ["Colonia Shopping", "Colonia Centro", "Juan Lacaze", "Tarariras", "Rosario", "Nueva Helvecia", "Cardona", "Otros"];
const forterCities = [
  "Dixie Cabal", 
  "Dixie Roxlo", 
  "Dixie Ejido", 
  "Dixie 8 Octubre", 
  "Dixie Geant", 
  "Dixie Paso Molino", 
  "Dixie Durazno", 
  "Outlet Tunel", 
  "Dixie Florida", 
  "Dixie Belloni", 
  "Dixie Colon", 
  "Dixie Uam", 
  "Dixie Cerro", 
  "Tres Cruces", 
  "Nuevo Centro", 
  "Portones", 
  "Montevideo Shopping", 
  "Salto", 
  "Costa Urbana", 
  "F. Atlantico", 
  "Otros"
];

const getAllCities = (tenant) => {
  return tenant === constants.FORTER_TENANT_NAME ? forterCities : losMuchachosCities;
};

// Function that loops through the locations array and returns an array of cities and sums the amount of each city
const getCityAmount = (locations) => {
  const cities = [];
  const citiesAmount = [];
  for (const location of locations) {
    const city = getCityName(location.latitude, location.longitude); // Remove 'await'
    if (cities.includes(city)) {
      citiesAmount[cities.indexOf(city)]++;
    } else {
      cities.push(city);
      citiesAmount.push(1);
    }
  }
  return [cities, citiesAmount];
};

// Function to group data by month and return an ordered array of entries
const groupDataByMonth = (data) => {
  const sortedDataByMonth = [];
  for (const location of data) {
    const date = convertTimestampToDate(location.timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for the month
    const monthYear = `${month}/${year}`;
    
    // Check if the month-year already exists in the sorted array
    const existingEntry = sortedDataByMonth.find(entry => entry[0] === monthYear);
    
    if (existingEntry) {
      existingEntry[1].push(location);
    } else {
      sortedDataByMonth.push([monthYear, [location]]);
    }
  }

  // Sort the array by month-year
  sortedDataByMonth.sort((a, b) => a[0].localeCompare(b[0]));

  return sortedDataByMonth;
};

// Function to calculate city-wise amounts by month
const calculateCityAmountsByMonth = (data) => {
  const cityAmountsByMonth = new Map();
  for (const [monthYear, locations] of data) {
    const [cities, citiesAmount] = getCityAmount(locations);
    cityAmountsByMonth.set(monthYear, { cities, citiesAmount });
  }
  return cityAmountsByMonth;
};

  // Function to calculate city-wise amounts by day
const calculateCityAmountsByDay = (data) => {
  const cityAmountsByDay = new Map();
  
  for (const location of data) {
    const date = convertTimestampToDate(location.timestamp);
    // const dayOfWeek = date.toLocaleDateString('es-ES', { weekday: 'short' }); // Get 3-letter day of the week
    const options = { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' };
    const day = date.toLocaleDateString('es-ES', options);
    // const day = `${dayOfWeek}  ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    
    if (!cityAmountsByDay.has(day)) {
      cityAmountsByDay.set(day, { cities: [], citiesAmount: [] });
    }
    
    const { cities, citiesAmount } = cityAmountsByDay.get(day);
    const city = getCityName(location.latitude, location.longitude);
    
    if (cities.includes(city)) {
      const cityIndex = cities.indexOf(city);
      citiesAmount[cityIndex]++;
    } else {
      cities.push(city);
      citiesAmount.push(1);
    }
  }

  // Convert the map into an array of key-value pairs and sort by day
  const sortedCityAmountsByDay = Array.from(cityAmountsByDay.entries()).sort(
    ([day1], [day2]) => {
      // Assuming the day string is in the format "dayOfWeek  DD/MM/YYYY"
      const [, date1] = day1.split(' ');
      const [, date2] = day2.split(' ');

      const dateParts1 = date1.split('/');
      const dateParts2 = date2.split('/');

      const date1Obj = new Date(`${dateParts1[2]}-${dateParts1[1]}-${dateParts1[0]}`);
      const date2Obj = new Date(`${dateParts2[2]}-${dateParts2[1]}-${dateParts2[0]}`);

      if (date1Obj < date2Obj) return -1;
      if (date1Obj > date2Obj) return 1;
      return 0;
    }
  );

  // Convert the sorted array back into a map
  const sortedMap = new Map(sortedCityAmountsByDay);

  console.log('sortedCityAmountsByDay', sortedCityAmountsByDay);

  return sortedMap;
  
};

// New component for ChartUsageByDay
const ChartUsageByDay = ({ locations }) => {
  const { tenant } = useParams();
  const [chartDataByDay, setChartDataByDay] = useState([]);
  const [loadingByDay, setLoadingByDay] = useState(true);

  const allCities = getAllCities(tenant);

  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [startDateByDay, setStartDateByDay] = useState(
    sevenDaysAgo.toISOString().split('T')[0]
  );

  const [endDateByDay, setEndDateByDay] = useState(today.toISOString().split('T')[0]);

  // State para los datos filtrados
  const [filteredChartData, setFilteredChartData] = useState(chartDataByDay);
  console.log('startDateByDay', startDateByDay);
  console.log('endDateByDay', endDateByDay);

  const handleFilterClick = () => {
    console.log("filterClicked", formatDateToDDMMYYYY(startDateByDay), formatDateToDDMMYYYY(endDateByDay));
    if (startDateByDay && endDateByDay && chartDataByDay && chartDataByDay.labels) {
      // Encuentra los índices de startDateByDay y endDateByDay en el arreglo labels
      const startDateIndex = chartDataByDay.labels.findIndex((label) =>
        label.includes(formatDateToDDMMYYYY(startDateByDay))
      );
      const endDateIndex = chartDataByDay.labels.findIndex((label) =>
        label.includes(formatDateToDDMMYYYY(endDateByDay))
      );
      console.log("filterClicked", startDateIndex, endDateIndex);
      if (startDateIndex !== -1 && endDateIndex !== -1) {
        
        // Filtra los datos en base a los índices de startDateByDay y endDateByDay
        const filteredData = chartDataByDay.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.slice(startDateIndex, endDateIndex + 1),
        }));
  
        // Actualiza los datos filtrados
        setFilteredChartData({
          labels: chartDataByDay.labels.slice(startDateIndex, endDateIndex + 1),
          datasets: filteredData,
        });
        console.log('filteredData', filteredData);
      }
    }
  };

  useEffect(() => {
    if (locations.length > 0) {
      const cityAmountsByDay = calculateCityAmountsByDay(locations);
      
      const labelsByDay = Array.from(cityAmountsByDay.keys());

      const sortedCities = allCities.slice().sort(); // Sort cities alphabetically

      const chartDataAuxByDay = {
        labels: labelsByDay,
        datasets: sortedCities.map((city) => ({
          label: city,
          data: labelsByDay.map((day) => {
            const { cities, citiesAmount } = cityAmountsByDay.get(day);
            const indexInCities = cities.indexOf(city);
            if (indexInCities !== -1) {
              return citiesAmount[indexInCities];
            } else {
              return 0;
            }
          }),
          backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.5)`,
        })),
      };

      console.log('chartDataAuxByDay', chartDataAuxByDay);

      setChartDataByDay(chartDataAuxByDay);
      setFilteredChartData(chartDataAuxByDay);
      setLoadingByDay(false);
    }
  }, [locations, allCities]);

  return (
    <>
      <input
        type="date"
        value={startDateByDay}
        onChange={(e) => setStartDateByDay(e.target.value)}
      />
      <input
        type="date"
        value={endDateByDay}
        onChange={(e) => setEndDateByDay(e.target.value)}
      />

      {/* Botón de filtro */}
      <button onClick={handleFilterClick}>Filtrar por día</button>
      {loadingByDay ? (
        <div className="loaderContainer">
          <div className="loader"></div>
        </div>
      ) : (
        <Bar options={optionsByDay} data={filteredChartData} />
      )}
    </>
  );
};


const ChartUsage = () => {
  const { tenant } = useParams();
  const [locations, setLocations] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [emailData, setEmailData] = useState({});
  const [totalUsage, setTotalUsage] = useState({});
  const [noEmailUsage, setNoEmailUsage] = useState([]);
  const [noEmailUsageByCity, setNoEmailUsageByCity] = useState({});
  const [uniqueDevicesByCity, setUniqueDevicesByCity] = useState({});
  const [totalUsageUniqueDevices, setTotalUsageUniqueDevices] = useState(0);
  const [noEmailUsageUniqueDevices, setNoEmailUsageUniqueDevices] = useState(0);
  const [error, setError] = useState(null);
  const [isFetchDataClicked, setIsFetchDataClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const allCities = getAllCities(tenant);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  
  // Establece el comienzo del mes actual
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  const [startDate, setStartDate] = useState(
    startOfMonth.toISOString().split('T')[0]
  );
  
  const [endDate, setEndDate] = useState(
    tomorrow.toISOString().split('T')[0]
  );

  const fetchData = async () => {
    try {
      console.log('startDate, endDate', startDate, endDate);
      // Parse the date strings and create Date objects at the start and end of the day
      const startDateObject = new Date(startDate + 'T00:00:00');
      const endDateObject = new Date(endDate + 'T23:59:59');
      console.log('startDateObject, endDateObject', startDateObject, endDateObject);

      const querySnapshot = await getDocs(
        query(
          getTenantCollection(tenant),
          where("timestamp", ">=", startDateObject),
          where("timestamp", "<=", endDateObject)
        )
      );
      
  
      const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      
      if (data.length === 0) {
        setError('No hay datos para el período seleccionado.');
        setLoading(false);
      } else {
        setError(null);
        setLocations(data);
        processDataForChart(data);
        processEmailDataByCityMonth(data);
        setLoading(false);
        console.log(data);
      }

      
    } catch (error) {
      console.error("Error fetching data:", error);
      setError('Error en la conexión al servidor.\n' + error);
      setLoading(false);
    }
  };

  const handleFetchDataClick = () => {
    setIsFetchDataClicked(true);
    setLoading(true);
    fetchData();
  }

  const processDataForChart = (data) => {
    // This code block will execute whenever `locations` is updated with data
    const groupedData = groupDataByMonth(data);
    const cityAmountsByMonth = calculateCityAmountsByMonth(groupedData);
    console.log('cityAmountsByMonth', cityAmountsByMonth);
    // Extract unique month-year combinations as labels
    const labels = Array.from(cityAmountsByMonth.keys());

    const sortedCities = allCities.slice().sort(); // Sort cities alphabetically

    const chartDataAux = {
      labels,
      datasets: sortedCities.map((city) => ({
        label: city,
        data: labels.map((monthYear) => {
          const { cities, citiesAmount } = cityAmountsByMonth.get(monthYear);
          const indexInCities = cities.indexOf(city);
          if (indexInCities !== -1) {
            return citiesAmount[indexInCities];
          } else {
            return 0; // Set to 0 when there's no data for the city
          }
        }),
        backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.5)`,
      })),
    };

    console.log('chartDataAux', chartDataAux);
    setChartData(chartDataAux);
  };

  const processEmailDataByCityMonth = (data) => {
    const emailUsage = {};
    let totalUsageCount = 0; // Contador para totalUsage
    let noEmailUsageCount = 0; // Contador para noEmailUsage
    const totalUsageDevices = new Set(); // Set para dispositivos únicos en totalUsage
    const noEmailUsageDevices = new Set(); // Set para dispositivos únicos en noEmailUsage
    const noEmailUsageByCity = {};
    const devicesByCity = {}; // Nuevo objeto para almacenar dispositivos únicos por ciudad
  
    data.forEach(entry => {
      const city = getCityName(entry.latitude, entry.longitude); // Obtén el nombre de la ciudad
      const date = new Date(entry.timestamp.seconds * 1000);
      const monthKey = `${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
      const email = entry.email; // Email del entry
      const deviceUUID = entry.deviceUUID; // Suponiendo que tienes un campo deviceUUID
  
      // Incrementa el contador total de uso
      totalUsageCount++;
  
      // Agrega el deviceUUID al set de dispositivos únicos
      if (deviceUUID) {
        totalUsageDevices.add(deviceUUID);
        if (!devicesByCity[city]) {
          devicesByCity[city] = new Set();
        }
        devicesByCity[city].add(deviceUUID);
      }
  
      // Verifica si el email está definido
      if (email) {
        if (!emailUsage[city]) {
          emailUsage[city] = {};
        }
        if (!emailUsage[city][monthKey]) {
          emailUsage[city][monthKey] = {};
        }
        // Incrementa el contador para este email
        if (!emailUsage[city][monthKey][email]) {
          emailUsage[city][monthKey][email] = 0;
        }
        emailUsage[city][monthKey][email] += 1;
      } else {
        // Incrementa el contador de noEmailUsage si el email no está definido
        noEmailUsageCount++;

        // Incrementa el total de usos sin email por ciudad
        noEmailUsageByCity[city] = (noEmailUsageByCity[city] || 0) + 1;
  
        // Agrega el deviceUUID al set de dispositivos únicos para noEmailUsage
        if (deviceUUID) {
          noEmailUsageDevices.add(deviceUUID);
        }
      }
    });
  
    // Guarda los datos en el estado
    setEmailData(emailUsage); // Guardar en emailData
    setTotalUsage(totalUsageCount); // Guardar en totalUsage
    setNoEmailUsage(noEmailUsageCount); // Guardar en noEmailUsage
    setNoEmailUsageByCity(noEmailUsageByCity); // Guardar el total sin email por ciudad
  
    // Guarda la cantidad de dispositivos únicos en cada categoría
    setTotalUsageUniqueDevices(totalUsageDevices.size); // Dispositivos únicos en totalUsage
    setNoEmailUsageUniqueDevices(noEmailUsageDevices.size); // Dispositivos únicos en noEmailUsage

    const uniqueDevicesByCity = {};
    for (const city in devicesByCity) {
      uniqueDevicesByCity[city] = devicesByCity[city].size;
    }

    setUniqueDevicesByCity(uniqueDevicesByCity); // Guardar en el estado
  };

  return (
    <div className="usageContainer">
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />

      {/* Botón de filtro */}
      <button onClick={handleFetchDataClick}>Traer Datos</button>
      {isFetchDataClicked && ( 
      <>
        {loading ? (
            <div className="loaderContainer">
              <div className="loader"></div>
              <p style={{ marginTop: '10px', fontSize: '16px', textAlign: 'center', color: '#333' }}>
                Esto puede tardar unos minutos...
              </p>
            </div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <>
              <Bar options={options} data={chartData} />
              <ChartUsageByDay locations={locations} />
              <EmailDataDisplay 
                emailData={emailData} 
                totalUsage={totalUsage} 
                noEmailUsage={noEmailUsage} 
                totalUsageUniqueDevices={totalUsageUniqueDevices} 
                noEmailUsageUniqueDevices={noEmailUsageUniqueDevices} 
                noEmailUsageByCity={noEmailUsageByCity} 
                uniqueDevicesByCity={uniqueDevicesByCity}
              />
            </>
          )
        }
      </>
      )}
      
    </div>
  );
}

// Function to convert timestamp to date
const convertTimestampToDate = (timestamp) => {
  const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
  return new Date(milliseconds);
};

export default ChartUsage;
