import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTenantCollection } from './Firebase.js';
import { addDoc, serverTimestamp } from 'firebase/firestore';
import { isAndroid, isIOSDevice } from '../utils';
import 'firebase/firestore';

const GeolocationTracker = ({ item, setErrorTracker }) => {
  const { tenant } = useParams();

  useEffect(() => {
    if (item == null) return;

    const trackGeolocation = async (position) => {
      const { latitude, longitude } = position.coords;

      const userEmail = localStorage.getItem('userEmail');
      const deviceUUID = localStorage.getItem('deviceUUID');  

      const newData = {
        latitude,
        longitude,
        timestamp: serverTimestamp(),
        id_item: item.id,
        refproveedor: item.refproveedor,
        email: userEmail,
        deviceUUID: deviceUUID
      };

      try {
        const docRef = await addDoc(getTenantCollection(tenant), newData);
        console.log('Document written with ID:', docRef.id, newData);
      } catch (error) {
        console.error('Error adding document:', error);
      }
    };

    const getInstructions = () => {
      if (isAndroid()) {
        return 'Para habilitar la ubicación en Android, ve a Configuración > Ubicación > Permitir acceso a la ubicación para esta aplicación.';
      } else if (isIOSDevice()) {
        return 'Para habilitar la ubicación en iOS, ve a Configuración > Privacidad > Servicios de ubicación > Permitir acceso a la ubicación para esta aplicación.';
      } else {
        return 'Por favor, habilita la ubicación en la configuración de tu dispositivo.';
      }
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        trackGeolocation,
        (error) => {
          console.error('Error al obtener la ubicación:', error);
          if (error.code === 1) {
            const instructions = getInstructions();
            setErrorTracker(`El acceso a la ubicación fue denegado. Habilítalo en la configuración del dispositivo para trackear tus escaneos.\n\n${instructions}`);
            alert(`El acceso a la ubicación fue denegado. La ubicación permite trackear los usos de cada escaneo.\n\n Ver más info en botón rojo 'Ubicacion deshabilitada.`);
          } else {
            setErrorTracker('Ocurrió un error al intentar obtener tu ubicación.');
          }
        }
      );
    } else {
      console.log('La geolocalización no es compatible con este navegador.');
      setErrorTracker('Tu navegador no soporta la geolocalización. No se contarán los usos del escaner');
    }
  // eslint-disable-next-line
  }, []);
  
  return (
    <>
    </>
  );
};
  
export default GeolocationTracker;
