import React from 'react';

const EmailDataDisplay = ({ 
  emailData, 
  totalUsage, 
  noEmailUsage, 
  totalUsageUniqueDevices, 
  noEmailUsageUniqueDevices,
  noEmailUsageByCity,
  uniqueDevicesByCity
}) => {
  // Calcula el total de usos con email
  const totalWithEmail = totalUsage - noEmailUsage;
  const emailUsageUniqueDevices = totalUsageUniqueDevices - noEmailUsageUniqueDevices;

  // Combina las ciudades de emailData y noEmailUsageByCity
  const allCities = new Set([...Object.keys(emailData), ...Object.keys(noEmailUsageByCity)]);

  // Ordena las ciudades alfabéticamente y coloca "otros" al final.
  const sortedCities = Array.from(allCities).sort((a, b) => {
    if (a.toLowerCase() === "otros") return 1;
    if (b.toLowerCase() === "otros") return -1;
    return a.localeCompare(b);
  });

  return (
    <div>
      {sortedCities.map((city) => (
        <div key={city}>
          {Object.entries(emailData[city] || {}).length > 0 ? (
            Object.entries(emailData[city]).map(([month, emails]) => (
              <div key={month}>
                <h6 style={{ color: 'black' }}>{city} - {month}</h6>
                {Object.entries(emails).length > 0 ? (
                  Object.entries(emails)
                    .sort(([emailA], [emailB]) => emailA.localeCompare(emailB)) // Ordena los emails alfabéticamente
                    .map(([email, count]) => (
                      <p key={email} style={{ color: 'black', fontSize: 10 }}>
                        {email}: {count}
                      </p>
                    ))
                ) : (
                  <p style={{ color: 'black', fontSize: 10 }}>No hay emails para este mes.</p>
                )}
              </div>
            ))
          ) : (
            <div>
              <h6 style={{ color: 'black' }}>{city}</h6>
            </div>
          )}
            {/* Mostrar total sin email por ciudad */}
            <p style={{ color: 'black', fontSize: 10, marginTop: '5px' }}>
                Total sin email para {city}: {noEmailUsageByCity[city] || 0}
                </p>
                <p style={{ color: 'black', fontSize: 10 }}>
                    Dispositivos únicos: {uniqueDevicesByCity[city] || 0}
            </p>
        </div>
      ))}

      {/* Mostrar el total de usos, los sin email y con email */}
      <div style={{ marginTop: '20px' }}>
        <h5 style={{ color: 'black' }}>Resumen General</h5>
        <p style={{ color: 'black', fontSize: 12 }}>
          <strong>Total de usos:</strong> {totalUsage}
        </p>
        <p style={{ color: 'black', fontSize: 12 }}>
          <strong>Con email:</strong> {totalWithEmail}
        </p>
        <p style={{ color: 'black', fontSize: 12 }}>
          <strong>Sin email:</strong> {noEmailUsage}
        </p>
      </div>

      {/* Mostrar los dispositivos únicos */}
      <div style={{ marginTop: '20px' }}>
        <h5 style={{ color: 'black' }}>Resumen de Dispositivos Únicos</h5>
        <p style={{ color: 'black', fontSize: 12 }}>
          <strong>Total de dispositivos únicos:</strong> {totalUsageUniqueDevices}
        </p>
        <p style={{ color: 'black', fontSize: 12 }}>
          <strong>Dispositivos únicos con email:</strong> {emailUsageUniqueDevices}
        </p>
        <p style={{ color: 'black', fontSize: 12 }}>
          <strong>Dispositivos únicos sin email:</strong> {noEmailUsageUniqueDevices}
        </p>
      </div>
    </div>
  );
};

export default EmailDataDisplay;