import '../App.css';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../components/Header';
import ScanButton from '../components/ScanButton';
import { ReactImageTint } from '../components/TintImage';
import miniButton from '../images/scanButton.png';
import { loadTenantConfiguration } from '../App';
import VersionTracker from '../components/VersionTracker';

const FORTER_TENANT_NAME = "forter";

const isBarcodeScannerEnabled = (tenant) => {
  return tenant === FORTER_TENANT_NAME;
}

const Index = () => {
  const { tenant, substore } = useParams();
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [loadingTenant, setLoadingTenant] = useState(true);

  useEffect(() => {
    loadTenantConfiguration(tenant, setIsDarkMode);
    setLoadingTenant(false);
  }, [tenant]);

  return (
    <div className={`background background-`+tenant}>
      {!loadingTenant && 
        <>
          <Header tenant={tenant} substore={substore} isDarkMode={isDarkMode} />
          <div className='mainContainer' style={{ height: '100vh' }}>
            {isDarkMode ? <div className='encontraTuTalle' /> :
                          <h3 style={{ fontStyle: 'italic', marginBottom: '40px' }}>ENCONTRÁ TU TALLE</h3>
                          
            }
            {isBarcodeScannerEnabled(tenant) && ( 
              <>
                <div className='briefExplanatioContainer'>
                  <h5>1) Presiona en el botón <ReactImageTint style={{ witdh: '25px', height: '25px', marginBottom: '-5px' }} src={miniButton} alt="logo" color="#000" /> de abajo a la derecha para comenzar</h5>
                  <h5>2) Dale permiso a tu cámara</h5>
                  <h5>3) Escanea el código de barra. Ayudate con el zoom para enfocar</h5>
                </div>
                <div className='scanButtonContainer'>
                <ScanButton tenant={tenant} substore={substore}/>
              </div>
              </>
              
            )}
          </div>
          <VersionTracker />
        </>
      }
    </div>
  );
};

export default Index; 
