import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, Rectangle, MarkerClusterer } from '@react-google-maps/api';
import { getTenantCollection } from './Firebase.js';
import { getDocs, query, where } from 'firebase/firestore';
import constants from '../constants';
import 'firebase/firestore';

const containerStyle = {
  width: '800px',
  height: '600px'
};

const latOffset = constants.CITIES_LATOFFSET;  // Aproximadamente 100 metros para la latitud
const lonOffset = constants.CITIES_LONOFFSET;  // Aproximadamente 100 metros para la longitud (varía según la latitud)
 
const MapUsage = () => {
  const { tenant } = useParams();
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const [isFetchDataClicked, setIsFetchDataClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [forceRenderMarkers, setForceRenderMarkers] = useState(null);

  const center = tenant === constants.FORTER_TENANT_NAME ? { lat: -34.9011127, lng: -56.1645314 } : { 
    lat: -34.397, 
    lng: -57.644
  };; 

  const cities = tenant === constants.FORTER_TENANT_NAME ? constants.CITIES_FORTER : constants.CITIES_LM;

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  
  // Establece el comienzo del mes actual
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  const [startDate, setStartDate] = useState(
    startOfMonth.toISOString().split('T')[0]
  );
  
  const [endDate, setEndDate] = useState(
    tomorrow.toISOString().split('T')[0]
  );

  const handleFetchDataClick = () => {
    setIsFetchDataClicked(true);
    setLoading(true);
    fetchData();
  }

  // Add this useEffect to set forceRenderMarkers to true after 1 second
  useEffect(() => {
    if (locations.length > 0) {
      const timer = setTimeout(() => {
        setForceRenderMarkers(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [locations]);

  const fetchData = async () => {
      try {
        // Parse the date strings and create Date objects at the start and end of the day
        const startDateObject = new Date(startDate + 'T00:00:00');
        const endDateObject = new Date(endDate + 'T23:59:59');
  
        const querySnapshot = await getDocs(
          query(
            getTenantCollection(tenant),
            where("timestamp", ">=", startDateObject),
            where("timestamp", "<=", endDateObject)
          )
        );
        
    
        const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        
        if (data.length === 0) {
          setError('No hay datos para el período seleccionado.');
          setLoading(false);
        } else {
          setError(null);
          setLocations(data);
          setLoading(false);
          console.log(data);
        }
  
        
      } catch (error) {
        console.error("Error fetching data:", error);
        setError('Error en la conexión al servidor.\n' + error);
        setLoading(false);
      }
  };

  return (
    <div className="usageContainer">
            
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />

      {/* Botón de filtro */}
      <button onClick={handleFetchDataClick}>Traer Datos</button>
      <LoadScript
        googleMapsApiKey="AIzaSyBV9b_h9cFuyTXzWDslKzYkaO0Hw3ctq14">
      {isFetchDataClicked && ( 
      <>
        {loading ? (
            <div className="loaderContainer">
              <div className="loader"></div>
              <p style={{ marginTop: '10px', fontSize: '16px', textAlign: 'center', color: '#333' }}>
                Esto puede tardar unos minutos...
              </p>
            </div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
              >
                { /* Child components, such as markers, info windows, etc. */ }
                {forceRenderMarkers && (
                  <>
                    <MarkerClusterer>
                      {(clusterer) =>
                        locations.map((location) => (
                          <Marker
                            key={location.id}
                            position={{ lat: location.latitude, lng: location.longitude }}
                            clusterer={clusterer}
                          />
                        ))
                      }
                    </MarkerClusterer>
                    
                    {cities.map(city => (
                      <Rectangle
                        key={city.name}
                        bounds={{
                          north: city.lat + latOffset,
                          south: city.lat - latOffset,
                          east: city.lon + lonOffset,
                          west: city.lon - lonOffset
                        }}
                        options={{
                          fillColor: 'rgba(0, 0, 255, 0.2)',
                          strokeColor: 'rgba(0, 0, 255, 0.5)',
                          strokeWeight: 1
                        }}
                      />
                    ))}
                  </>
                )}
              </GoogleMap>
          )
        }
      </>
      )}
      </LoadScript>
      
    </div>
    
  );
}

export default MapUsage;
